
export const getCableEndpoint = () => {
    const hostname = window && window.location && window.location.hostname;

    if (
        hostname !== "localhost" &&
        !hostname.includes("staging") &&
        hostname.includes("boom")
    ) {
        return "wss://cable.boomhub.app/cable";
    }
    if (hostname !== "localhost" && !hostname.includes("staging")) {
        return "wss://cable.goapps.app/cable";
    }
    if (hostname === "localhost") {
        return "wss://goportal-cable.herokuapp.com/cable";
    }
    return "wss://goapps-staging-cable.herokuapp.com/cable";
};


export const getEndPoint = () => {
    const hostname = window && window.location && window.location.hostname;

    if (
        hostname !== "localhost" &&
        !hostname.includes("staging") &&
        hostname.includes("boom")
    ) {
        return "https://api-read.boomhub.app";
    }
    if (hostname !== "localhost" && !hostname.includes("staging")) {
        return "https://api.go-read.app";
    }
    if (hostname === "localhost") {
        return "https://goread-api.herokuapp.com";
    }
    if (hostname.includes("boom")) {
        return "https://staging.api-read.boomhub.app";
    }
    return "https://staging-api.go-read.app";


};
