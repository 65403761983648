// This will check to see if there's a token, set the token if so, and check internal log in is alight
import React, { Fragment, useContext, useEffect, useState } from 'react';
import ls from 'local-storage';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { parse } from 'query-string';
import { useEffectOnce } from 'react-use';
import { navigate } from '@reach/router';

import { Loader } from '@axeedge/go-teacher-components';
import { getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';
import { ErrorPage } from '@axeedge/go-shared-components';
import { ActionCableWrapper, NotifCableConsumer } from './ActionCableWrapper';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { ERROR, LOGGED_IN } from '../services/graphql';
import { APP_NAME } from '../../../services/constants';
import UserQueryHolder from './UserQueryHolder';
import RouteCapture from './RouteCapture';
import Header from '../../../components/Header';
import Nav from '../../../components/Nav';
import PupilCsvExport from './PupilCsvExport';
import JobRoleCheck from './JobRoleCheck';

const TokenCheck = ({ children, dialog, location }) => {
   // console.log(ls('go-read-teacher-token'))
    const [token, setToken] = useState(ls(getAppTokenName(APP_NAME)));
    const [loggingOut, setLoggingOut] = useState(false);
    const { data, error, loading } = useQuery(LOGGED_IN);
    const { data: clientError } = useQuery(ERROR);
    const client = useApolloClient();
    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const onLogout = () => {
        setLoggingOut(true);
        setToken(null);
        ls.clear();
        setCurrentUser(null);
        client.writeData({
            data: {
                loggedIn: false
            }
        });
        window.location.href = `${getUrl(APPS.go_portal_teacher)}/logout`;
    }

    const onForcedLogout = () => {
        setLoggingOut(true);
        setToken(null);
        ls.clear();
        setCurrentUser(null);
        client.writeData({
            data: {
                loggedIn: false
            }
        });
        window.location.href = `${getUrl(APPS.go_portal_teacher)}/forceLogout`;
    }

    useEffectOnce(() => {
        if (location.pathname.includes('impersonate')) {
            ls('go_impersonating', true);
            const schoolId = new URLSearchParams(location.search).get('schoolId');
            if (schoolId) {
                ls('schoolId', schoolId); 
            }
            // Extract the base path without 'impersonate'
            const newPath = location.pathname.replace('/impersonate', '');
            navigate(newPath || '/'); // Navigate to the updated path or root if empty
        }
    })

    useEffect(() => {
        if (!token && !loggingOut) {
            const tokenInUrl = parse(location.search).token;
            if (tokenInUrl) {
                setToken(tokenInUrl);
                ls(getAppTokenName(APP_NAME), tokenInUrl);
                client.writeData({
                    data: {
                        loggedIn: tokenInUrl,
                        forcedLogout: false
                    }
                });
            } else {
                window.location.href = `${getUrl(APPS.go_portal_teacher)}/redirectAfterLogin/${APP_NAME}?targetUrl=${location.pathname}`;
            }
        }
    }, [token, client, location.pathname, location.search, loggingOut])

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (clientError && clientError.error) {
        return <ErrorPage />
    }

    if (data && data.loggedIn) {
        return (
            <Fragment>
                <RouteCapture />
                <UserQueryHolder />
                <ActionCableWrapper>
                    {currentUser && (
                        <>
                            <NotifCableConsumer id={currentUser.id} onLogout={onLogout} onForcedLogout={onForcedLogout} />
                            <PupilCsvExport />
                        </>
                    )}
                    {
                        currentUser && dialog ? children : (
                            <div className="app__layout">
                                {ls('go_impersonating') && <p className='impersonating'>Impersonating Active</p>}
                                {currentUser &&
                                <Nav user={currentUser} onLogout={onLogout} />}
                                {currentUser && !currentUser.roleId && <JobRoleCheck />}
                                <div className={`app__content ${location.pathname.includes('/student/') && `app__content--noSidePadding`}`}>
                                    {children}
                                </div>
                            </div>
                        )
                    }
                </ActionCableWrapper>
            </Fragment>

        )
    }

    return null;
}

export default TokenCheck;
