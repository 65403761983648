import { ApolloClient } from "apollo-boost";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import ls from "local-storage";

import { getEndPoint } from "./endPoints";
import { getAppTokenName} from './global_constants';

function getQueryParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export const getClient = (app) => {
    const cache = new InMemoryCache({
        dataIdFromObject: (object) => `${object.__typename}-${object.id}` || null,
    });

    cache.originalReadQuery = cache.readQuery;
    cache.readQuery = (...args) => {
        try {
            return cache.originalReadQuery(...args);
        } catch (err) {
            return undefined;
        }
    };

    // General link for backend requests
    const httpLink = createHttpLink({
        uri: `${getEndPoint()}/graphql`,
    });

    // Chucks in the auth stuff
    const authLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them

        const schoolIdFromUrl = getQueryParam('schoolId');
        if (schoolIdFromUrl) {
            ls('schoolId', schoolIdFromUrl);
        }
        const schoolId = schoolIdFromUrl || ls('schoolId') || '';

        return {
            headers: {
                ...headers,
                Authorization: ls(getAppTokenName(app)) || "",
                SchoolId: schoolId
            }
        };
    });

    // Error handler if request is unauthorised
    const errorLink = onError(
        ({ graphQLErrors, networkError, operation, response, forward }) => {
            ls("errorData", JSON.stringify(response));
            if (graphQLErrors) {
                graphQLErrors.map(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    )
                );
                cache.writeData({
                    data: {
                        error: true,
                    },
                });
            }

            if (networkError) {
                console.log(`[Network error]: ${networkError.statusCode}`);
                if (networkError.statusCode === 401) {
                    cache.writeData({
                        data: {
                            forcedLogout: true,
                        },
                    });
                    ls.remove(getAppTokenName(app));
                } else {
                    cache.writeData({
                        data: {
                            error: true,
                        },
                    });
                }
            }
            return forward(operation);
        }
    );

    // Set up the client
    const client = new ApolloClient({
        link: ApolloLink.from([authLink, errorLink, httpLink]),
        cache,
        resolvers: {},
    });

    cache.writeData({
        data: {
            error: false, // if true show error page
            loggedIn: ls(getAppTokenName(app)), // is the user logged in
            forcedLogout: false, // has the user just been kicked out for having a bad token
        },
    });

    return client;
};
